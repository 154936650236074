export default {
    message: {
        home: '主頁',
        login: '登入',
        accountLogin: '帳號登入',
        pleaseEnterAccountName: '請輸入帳號名',
        pleaseEnterYourPassword: '請輸入登入密碼',
        exitSystem: '退出系統',
        reminder: '溫馨提示',
        exitSystemTips: '是否確認退出系統？',
        pleaseEnterNamePassword: '請輸入正確的用戶名和密碼',
        // menu
        wordCloud: '詞雲',
        commentRanking: '評論排名',
        setup: '設定',
        setVocabulary: '設定詞彙',
        //詞雲
        selectTime: '請先選擇時間段',
        wordcloudClickSearchGetData: '請點擊蒐索,獲取數據',
        wordcloudNoDataFound: '未蒐索到數據',
        wordcloudHeadwordSymbols: '中心詞含有特殊符號,請先删除',
        wordcloudNotSupportSymbols: '不支持輸入特殊符號,只能輸入中文、英文、數位',
        wordcloudHeadword: '中心詞',
        wordcloudPleaseFillOut: '請填寫',
        wordcloudPlatform: '平臺',
        wordcloudPleaseChoose: '請選擇',
        wordcloudSelectAll: '全選',
        wordcloudBrand: '品牌',
        wordcloudPeriod: '時間段',
        wordcloudStartingTime: '開始時間',
        wordcloudEndTime: '結束時間',
        wordcloudNumberWords: '詞數量',
        reset: '重置',
        search: '蒐索',
        keywords: '關鍵字',
        //評論
        ECProductIDsTips: '用逗號隔開已輸入的ids',
        ECProductIDsTipsReg: '支持輸入僅數位的id（用逗號隔開已輸入的ids）',
        new: '新增',
        filterWord: '過濾詞',
        username: '用戶名',
        date: '日期',
        actions: '操作',
        delete: '删除',
        addFilter: '添加過濾詞',
        "confirm": '確認',
        "cancel": '取消',
        deleteConfirm: '删除確認',
        deleteConfirmTips: '確定要删除嗎？',
        clickQueryData: '點擊蒐索按鈕査詢數據',
        original: '原文',
        heat: '熱度',
        filter: '過濾官方帳號',
        details: '點擊查看詳情',
        comment: '評論',
        sort: '分類',
        contact: '聯繫我們',
        numLimit: '請在1-100之間選擇數位',
        placeholderNum: '請輸入詞數量',
        buzz: '品牌聲量',
        productCode: '產品編碼',
        brand: '品牌',
        productDesc: '產品描述',
        category: '品類',
        SBU: 'SBU',
        year: '年份',
        season: '季節',
        gender: '性別',
        color: '顏色',
        colorGroup: '顏色組',
        score: '得分',
        recommendedNumber: '推薦個數',
        intelligentRecommendation: '智慧推薦',
        selectProduct: '請至少選擇一款產品',
        recommend: '推薦',
        pleaseInput: '請輸入',

        copywriting: '文案',
        //文案
        activityContent: '活動內容',
        productName: '產品名稱',
        productNamePlaceholder: '產品名稱描述',
        eventTheme: '活動主題',
        eventThemePlaceholder: '活動主題描述',
        productFeatures: '產品特點',
        productFeaturesPlaceholder: '其他產品特點（限50字內）',
        targetAudience: '目標人群',
        populationAge: '人群年齡',
        populationAgePlaceholder: '數值或描述',
        crowdInterests: '人群興趣',
        crowdInterestsPlaceholder: '人群興趣（限50字內）',
        otherFeatures: '其他特徵',
        otherFeaturesPlaceholder: '其他人群特徵（限50字內）',
        articleRequirements: '文章要求',
        copywritingTone: '文案語氣',
        wordNumber: '文案字數',
        wordNumberPlaceholder: '至多字數（最多2000字）',
        deliveryChannels: '投放通路',
        generateContent: '生成內容',
        generateTokenNumber: '產生token數',
        modificationComments: '修改意見',
        modificationCommentsPlaceholder: '輸入限制在100字以內',
        adjustingContent: '調整內容',
        lively: '活潑',
        amusing: '有趣',
        official: '官方',
        polite: '客氣',
        casual: '休閒',
        introverted: '內斂',
        passionate: '激情',
        literary: '艺文',
        solemn: '莊重',
        male: '男',
        female: '女',
        unlimited: '不限',
        copywritingTips: '不要洩露任何公司機密資訊，使用中的任何問題，可點擊右上角問題迴響按鈕，進行迴響，謝謝！',
        copywritingTips2: '不要透露員工、承包商、消費者等的任何個人資訊',
        copywritingTips3: '不要上傳任何VF知識產權或使用現有VF知識財產創建任何新材料',
        redbook: '小紅書',
        weibo: '微博',
        officialAccount: '微信公眾號',
        textMessage: '簡訊',
        wecom: 'WeCom',
        advertisement: '廣告',
        shortvideo: '短視頻',
        creativeWriting: '創意文',
        instagram: 'Instagram',
        facebook: 'Facebook',
        livestream: '直播',
        waitingMessage: '正在生成文案，請稍等',

        // 问题反馈
        feedbackButton: '问题反馈',
        feedbackPlaceholder: '請輸入使用過程中遇到的問題，或者是想要的功能，收到後我們會儘快處理',
        select: '請選擇',
        SEOOpti: 'SEO優化',
        "submitNow": "提交",
        getAuthenticationUrl: "獲取認證地址中",
        customLogin: "驗證用戶中",
        authenticateFail: "授權失敗，即將返回登入頁",
        hotWords: '熱詞',
        seoHotWordsPlaceholder: '可輸入SEO熱詞，多個詞語用逗號隔開',
        refPicture: '參考圖片',
        translate: '翻譯',
        translateInputPlaceholder: '輸入原文',
        translateFromLanguage: '源語言',
        translateToLanguage: '目標語言',
        translateTone: '語氣',
        translateAuto: '自動檢測',
        translateDocUpload: '上載文件，支援格式：.pptx,.docx,.pdf',
        translateDocDownload: '文件翻譯歷史',
        translateDocUploadBt: '文檔翻譯上載',
        translateDocDownloadBt: '文檔翻譯記錄',
        noDate: '無數據',
        question: '提問',
        questionInputPlaceholder: '輸入問題',
    imageInputPlaceholder: '輸入描述',
    "downloadAll": "一鍵下載",
    imageWaitingMessage: '正在生成圖片，請稍等',
    downloadImages: '下載圖片',
    image: '圖片',
    askQuestion: '提問',
    imageGeneration: '圖片生成',

    batchCopyGenerationTemplate: '批量文案生成範本',
    templateDownload: '範本下載',
    upload: '上傳',
    generateTemplatesBatchTranslation: '批量翻譯生成範本',
    batchTips:'批量任務僅保留1年，請及時下載',
    taskName: '任務名稱',
    filePath: '檔案路徑',
    number: '序號',
    taskTyps: '任務類型',
    creator: '創建者',
    creationTime: '創建時間',
    taskStatus: '任務狀態',
    download: '下載',
    refresh: '刷新',
    pleaseEnterName: '請輸入名稱',
    uploadCopywriting: '上傳文案',
    uploadTranslation: '上傳翻譯',
    batchTasks: '批量任務',
    choose: '選擇',
    translation: '翻譯',
    inProgress: '進行中',
    error: '錯誤',
    completed: '完成',

    knowledge: '知識問答',
    searchResult: '找到相關結果如下',
    relatedContentLink: '相關內容原文',
    searchSummary: '答案如下',

      //Chat Assistant
      chatAssistant:'Chat Assistant',
      send:'遞交',
      topic:"主題",
      chatnotice1:'新的對話已開啟',
      chatnotice2:'之前的對話內容不被引用',
         //upload Document

     uploadDocument:'文件上傳',
     inputDocument:'輸入搜尋關鍵字',
     uploadFileName:'關鍵字',
    uploadTips:"已存在同名文件，上传此文件將覆蓋原文件",

    modelTips: "翻譯至日韓文可使用GPT4o1模型(頁面右上角切換）以達到更好質素"
    }
}