import { queryDate } from '~/api/wordcloud';
import { uploadSuccess } from './uploaddoc';
const { VITE_API: querylink } = import.meta.env;
// const querylink = import.meta.env.MODE === 'prod' ? 'ai': 'ai-dev'

export const serviceUrlConfig = {
  queryWordcloud: 'api/query/wordcloud',
  queryStopwords: 'api/query/stopwords',//过滤词
  queryBarchart: 'api/query/barchart', //评论
  queryPie: 'api/query/pie', //饼图
  userLogin: querylink + '/user/login',
  queryDate: 'api/query/date', //获取时间
  queryComment: 'api/query/comment',
  queryBuzz: 'api/query/trend',
  querySearch: 'api2/query/column', // 模糊查询条件
  queryProduct: 'api2/query/search',
  queryRecommandProduct: 'api2/query/basket', // 智能推荐产品查询
  copywritingGeneration: querylink + '/query/write',
  copywritingRewrite: querylink + '/query/rewrite',
  feedback: querylink + '/query/feedback',
  authRedirect: querylink + '/auth/redirect',
  authCallback: querylink + '/auth/callback',
  authSignOut: querylink + '/auth/sign_out',// 用户退出
  translate: querylink + '/query/translate', // 翻译
  chat: querylink + '/query/chat',
  image: querylink + '/query/image', //图片生成
  download: querylink + '/query/batch/download/template', //下载
  taskList: querylink + '/query/batch/list',
  batchImport: querylink + '/query/batch/upload',
  downloadTemplate: querylink + '/query/batch/download/template', //下载模板
  downloadFile: querylink + '/query/batch/download/file',
  refreshTask: querylink + '/query/batch/list', //刷新
  batchTemplate: querylink + '/query/batch/download/template', //下载模版
  batchJobList: querylink + '/query/batch/list', //历史任务列表
  batchUpload: querylink + '/query/batch/upload', //上传任务文件
  batchDownload: querylink + '/query/batch/download/file', //下载完成任务文件
  csSearch: querylink + '/cs/search', //知识库搜索问答
  translateDocUpload: querylink + '/translate/upload', //翻译文件上传
  translateDocUploadSts: querylink + '/translate/oss/path', //上传oss 的sts
  translateSubmitUpload: querylink + '/translate/oss/callback', //上传完提交
  
  translateDocList: querylink + '/translate/list', //翻译文件历史列表
  translateDocDownload: querylink + '/translate/download', //翻译文件下载
  userTopicList: querylink + '/user/topic/list', //用户可查看的知识库主题列表
  userGroupList: querylink + '/user/group/list', //用户所在组
  uploadList: querylink + '/cs/doc/list', //获取上传文件list
  uploadTopicList: querylink + '/user/topic/list', //获取上传文件list
  uploadSuccess:querylink + '/cs/oss/callback', //上传文件成功
  removeUploadFile:querylink + '/cs/doc/remove', //删除
  getUploadPath:querylink + '/cs/oss/path', //删除
  downloadUploadFile:querylink + '/cs/doc/download',
  searchDocUpload: querylink + '/cs/upload' //知识库文件上传


}

